<template>
  <div class="company-bg">
    <div class="container">
      <!-- 如果是社会单位加上society-bg的样式 -->
      <div :class="['banner', type == 3 ? 'society-bg' : '']">
        <!-- 国家级 -->
        <div class="banner-title">
          <span v-if="type == 1">国家级</span>
          <span v-if="type == 2">省级</span>
          <span>安全文化示范企业</span>
        </div>

        <!-- 省级标题 -->
        <!-- <div class="banner-title" >
      <span>省级</span>
      <span>安全文化示范企业</span>
    </div> -->

        <!-- 社会单位标题 -->
        <div v-if="type == 3"
             class="banner-title">
          <span>示范名录</span>
          <span>社会单位安全文化建设</span>
        </div>
        <img v-if="type != 3"
             src="../../assets/company/banner-country.png"
             class="banner-country" />
        <img v-if="type == 3"
             src="../../assets/company/banner-society.png"
             class="banner-society" />
      </div>
      <div class="content-wrap">
        <!-- 如果是企业单位上面有选项卡 -->
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/home">首页</a></li>
            <li class="breadcrumb-item active"
                aria-current="page">企业名录</li>
          </ol>
        </nav>

        <!-- 如果是社会单位橙色没有筛选 -->
        <div class="filter-box">
          <select v-if="type != 3"
                  class="form-select"
                  aria-label="Default select example"
                  @change="toChangeSelect($event, 1)">
            <!-- <option value=""
                    selected>按取证年份</option> -->
            <option :value="item"
                    :selected="index==0"
                    v-for="(item, index) in years"
                    :key="index">
              {{ item }}
            </option>
          </select>
          <select v-if="type == 2"
                  class="form-select"
                  aria-label="Default select example"
                  @change="toChangeSelect($event, 9)">
            <option value="1"
                    selected>首次创建</option>
            <option value="2">存续企业</option>
          </select>
          <select v-if="type != 3"
                  class="form-select"
                  aria-label="Default select example"
                  @change="toChangeSelect($event, 3)">
            <option value=""
                    selected>按地区</option>
            <option :value="item.area_id"
                    v-for="(item, index) in citys"
                    :key="index">
              {{ item.area_name }}
            </option>
          </select>
          <select v-if="type != 3"
                  class="form-select"
                  aria-label="Default select example"
                  @change="toChangeSelect($event, 2)">
            <option value=""
                    selected>按行业</option>
            <option :value="item"
                    v-for="(item, index) in industry"
                    :key="index">
              {{ item }}
            </option>
          </select>
          <SearchBar @toSearch="toSearch"></SearchBar>
        </div>
        <!-- 选项卡 -->
        <ul class="nav nav-pills nav-orange"
            id="myTab"
            role="tablist"
            v-if="type == 3">
          <li class="nav-item"
              role="presentation"
              v-for="item in category"
              :key="item.paper_type_id"
              @click="changeCategory(item)">
            <button :class="[
                'nav-link',
                curCategory.code == item.code ? 'active' : '',
              ]"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="home-tab-pane"
                    aria-selected="true">
              {{ item.type_name }}
            </button>
          </li>
        </ul>
        <div class="company-list">
          <div class="company-item"
               v-for="item in dataList.list"
               :key="item.company_id"
               @click="toDetail(item)">
            <div class="company-name">{{ item.name }}</div>
            <!-- 如果是社会单位可能没有company-info -->
            <div class="company-info"
                 v-if="type == 2">
              <div class="company-nature">{{ item.tag }}</div>
              <span>首次取证时间 {{ item.first_approve_year }}年</span>
            </div>
            <div class="name-box"
                 v-else-if="type == 1">
              <span class="name-country">{{ item.named_at }}全国安全文化建设示范企业</span>
              <img src="../../assets/company/name-shape.png"
                   class="name-shape" />
            </div>
            <div class="company-intro">
              {{ item.intro }}
            </div>
            <!-- 复审时间只有省级有 -->
            <div v-if="type == 2"
                 class="company-recheck">
              <div v-if="item.expired_at.length > 0">
                <div class="status-expair">
                  {{ item.expired_at }}年度未按要求重新申报
                </div>
              </div>
              <div v-else>
                <div v-if="item.next_check_year > 0">
                  下次复审时间：{{ item.next_check_year }}
                </div>
                <div v-else-if="
                    item.recheck_status == '1' && item.recheck_year > 0
                  ">
                  重新申报时间：{{ item.recheck_year }}
                </div>
                <div v-else>
                  <view class="status-fail">{{ item.recheck_time }}年度复审未通过</view>
                </div>
              </div>
            </div>
            <div class="company-shape"></div>
            <div class="company-pattern">
              <i class="iconfont icon-a-xingzhuang2"></i>
            </div>
          </div>
        </div>
        <Pagination @change="pageChanged"
                    :current="1"
                    :pageSize="10"
                    :hideOnSinglePage="true"
                    :total="parseInt(dataList.total)"
                    :showQuickJumper="false"
                    :showTotal="false"
                    placement="center" />
      </div>
    </div>
  </div>
</template>
<script>
import {
  socialCategory,
  getBaseData,
  companyList,
  paperList,
} from "@/network/API.js";
import SearchBar from "@/components/SearchBar.vue";
import Pagination from "@/components/Pagination.vue"
export default {
  components: {
    SearchBar,
    Pagination,
  },
  data() {
    return {
      type: 0, // 1:国家 2:省级 3:社会单位
      dataList: {},
      pageNumber: 1,
      searchKey: "",
      category: [],
      curCategory: {},
      total: 1,
      selApproveType: 1,
      tid: 1,

      years: [],
      industry: [],
      citys: [],
      selYear: "2023",
      selIndustry: "",
      selCity: {},
    };
  },
  created() {
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }
    if (this.$route.query.tid) {
      this.tid = this.$route.query.tid;
      console.log("tid is " + this.tid);
    }
    if (this.type == 2 && this.tid == 2) {
      this.getSocialCategory();
    }
    if (this.type == 3) {
      this.getSocialCategory();
      this.getDataList();
    } else {
      this.getSelectData();
      this.getDataList();
    }
  },
  methods: {
    toSearch(val) {
      this.searchKey = val;
      this.pageNumber = 1;
      this.getDataList();
    },
    clearSearch() {
      this.searchKey = "";
      this.toSearch();
    },
    async getDataList(code, page = this.pageNumber) {
      if (this.type == 3) {
        let data = await paperList({
          code: this.curCategory.code,
          page: page,
          page_size: 10,
          keyword: this.searchKey,
        });
        this.dataList = data;
      } else {
        this.pageNumber = page;
        var params = {
          company_type: this.type,
          page: page,
          page_size: 10,
          keyword: this.searchKey,
          recheck_year: this.selYear,
          industry: this.selIndustry || "",
          city_code: this.selCity || "",
          recheck_status: 1,
          city_code: this.selCity,
          approve_type: this.selApproveType,
          type_id: this.tid
        }
        let data = await companyList({
          ...params
        });
        this.dataList = data;
      }
    },
    async getSocialCategory() {
      let data = await socialCategory();
      if (data.length > 0) {
        this.category = data;
        this.curCategory = data[0];
        this.industry = data.map(item => {
          return item.type_name
        })
      }
    },
    async getSelectData() {
      this.years = [2023, 2022, 2021, 2019, 2018, 2017, 2016, 2015, 2014, 2013]
      if (this.type == 1) {
        this.years = ["按年度", 2023, 2022, 2021, 2019, 2018, 2017, 2016, 2015, 2014, 2013]
      }
      this.selYear = this.type == 1 ? "" : 2023
      this.industry = await getBaseData("trade");
      this.citys = await getBaseData("city_list");
    },
    toChangeSelect(event, type) {
      if (type == 1) {
        this.selYear = event.target.value == "按年度" ? "" : event.target.value;
      }
      if (type == 2) {
        this.selIndustry = event.target.value;
      }
      if (type == 3) {
        this.selCity = event.target.value;
      }
      if (type == 9) {
        this.selApproveType = event.target.value
      }
      this.getDataList();
    },
    toDetail(item) {
      this.$router.push({
        name: "CompanyDetail",
        params: {
          id: item.company_id,
          type: this.type,
        },
      });
    },
    changeCategory(category) {
      this.curCategory = category;
      this.getDataList();
    },
    pageChanged(val) {
      this.pageNumber = val.page;
      this.getDataList();
    },
  },
};
</script>
<style scoped>
.banner-society {
  position: absolute;
  right: 0px;
  top: 50px;
  width: 344px;
  height: 344px;
  display: block;
}

.search-box {
  right: 0;
  top: 0;
}

.search,
.search input {
  background: #ebf3fd;
}

.society-bg .search {
  background: #fff5eb;
}

.society-bg .company-shape {
  background: linear-gradient(134deg, #ffd993 2%, #ff6d00 100%);
}

.society-bg .icon-a-xingzhuang2 {
  color: #ff6d00;
}

.society-bg .banner-country {
  display: none;
}

.society-bg .banner-society {
  display: block;
}

.society-bg .company-recheck {
  display: none;
}

.society-bg .company-info,
.society-bg .filter-box {
  display: none;
}

.filter-box .form-select {
  margin-right: 20px;
  width: 130px;
}

/* 选项卡 */

.nav-pills.nav-orange .nav-link {
  margin: 0;
  margin-right: 20px;
  padding-left: 30px;
  padding-right: 30px;
  color: #333;
}

.nav-pills.nav-orange .nav-link:hover {
  color: #ff6d00;
}

.nav-pills.nav-orange .nav-link.active {
  background: linear-gradient(107deg, #ffd993 0%, #ff6d00 99%);
  color: #fff;
  border-radius: 50px;
  border: none;
}

.status-expair {
  color: #ff6d00;
}

.status-fail {
  color: #ff3241;
}
</style>